import { z } from 'zod'

export const emailSchema = z
  .string()
  .email({ message: 'El email no es valido' })
  .min(3, { message: 'El email es demasiado corto' })
  .max(100, { message: 'El email es demasiado largo' })

export const passwordSchema = z
  .string()
  .min(4, { message: 'La contraseña es demasiado corta' })
  .max(100, { message: 'La contraseña es demasiado larga' })
  .refine(s => !s.includes(' '), 'Los espacios no son validos')

