import {
  json,
  type MetaFunction,
  type ActionFunctionArgs,
  redirect,
} from '@remix-run/node'
import { Form, Link, useActionData, useSearchParams } from '@remix-run/react'
import { GeneralErrorBoundary } from '~/components/error-boundary.tsx'
import { Spacer } from '~/components/ui/spacer.tsx'
import { AuthLayout } from '~/components/layout/auth-layout.tsx'
import { z } from 'zod'
import { passwordSchema } from '~/utils/zod-extensions.ts'
import { combineResponseInits, useIsPending } from '~/utils/misc.tsx'
import { checkHoneypot } from '~/utils/honeypot.server.ts'
import { safeRedirect } from 'remix-utils/safe-redirect'
import { AuthenticityTokenInput } from 'remix-utils/csrf/react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import {
  ErrorList,
  Field,
} from '~/components/ui/inputs/forms.tsx'
import { Label } from '~/components/ui/inputs/label.tsx'
import { StatusButton } from '~/components/ui/inputs/button/status-button.tsx'
import { authSessionStorage } from '~/utils/session.server.ts'
import { login, sessionKey } from '~/utils/auth.server.ts'
import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'

export const meta: MetaFunction = () => {
  return [{ title: 'Iniciar sesion - TIR' }]
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData()
  // await validateCSRF(formData, request.headers)
  checkHoneypot(formData)
  const submission = await parseWithZod(formData, {
		schema: intent =>
			LoginFormSchema.transform(async (data, ctx) => {
				if (intent !== null) return { ...data, sessionId: null }

        // TODO auth with odoo
        const sessionId = await login({ username: data.username, password: data.password })
        if (!sessionId) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Email o contraseña incorrecto',
          })
          return z.NEVER
        }

        return { ...data, sessionId }
      }),
    async: true,
  })

	if (submission.status !== 'success' || !submission.value.sessionId) {
		return json(
			{ result: submission.reply({ hideFields: ['password'] }) },
			{ status: submission.status === 'error' ? 400 : 200 },
		)
	}

  const { redirectTo, sessionId } = submission.value

  // return redirect(safeRedirect(redirectTo))
  return handleNewSession({
    request,
    sessionId,
    redirectTo,
  })
}

export async function handleNewSession(
  {
    request,
    sessionId,
    redirectTo,
  }: {
    request: Request
    sessionId: string
    redirectTo?: string
  },
  responseInit?: ResponseInit,
) {
  const authSession = await authSessionStorage.getSession(
    request.headers.get('cookie'),
  )

  authSession.set(sessionKey, sessionId)

  return redirect(
    safeRedirect(redirectTo),
    combineResponseInits(
      {
        headers: {
          'set-cookie': await authSessionStorage.commitSession(authSession),
        },
      },
      responseInit,
    ),
  )
}


const LoginFormSchema = z.object({
  username: z.string(),
  password: passwordSchema,
  redirectTo: z.string().optional(),
})

export default function LoginPage() {
  const actionData = useActionData<typeof action>()
  const isPending = useIsPending()
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo')

  const [form, fields] = useForm({
    id: 'login-form',
    constraint: getZodConstraint(LoginFormSchema),
    defaultValue: { redirectTo },
    lastResult: actionData?.result,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: LoginFormSchema })
    },
    shouldRevalidate: 'onBlur',
  })

  return (
    <AuthLayout>
      <Spacer size="4xs" />
      <div>
        <div className="mx-auto w-full max-w-md ">
          <Form method="POST" name="login" {...getFormProps(form)}>
            <AuthenticityTokenInput />
            <HoneypotInputs />
            <Field
              labelProps={{ children: 'Username' }}
              inputProps={{
                ...getInputProps(fields.username, {type: 'text'}),
                autoComplete: 'username',
                autoFocus: true,
                placeholder: 'Username',
              }}
              errors={fields.username.errors}
            />
            <Spacer size="4xs" />
            <Field
              labelProps={{
                asChild: true,
                children: (
                  <div className="flex gap-8 items-center justify-between">
                    <Label htmlFor={fields.password.id}>Contraseña</Label>
                    <div className="mb-2">
                      <Link
                        to={`/forgot-password`}
                        className="text-body-xs font-semibold"
                      >
                        ¿Olvidaste la contraseña?
                      </Link>
                    </div>
                  </div>
                ),
              }}
              inputProps={{
                ...getInputProps(fields.password, {
                  type: 'password',
                }),
                placeholder: 'Contraseña',
                autoComplete: 'current-password',
              }}
              errors={fields.password.errors}
            />
            <Spacer size="4xs" />

            <input {...getInputProps(fields.redirectTo, {type: 'hidden'})} />
            <ErrorList errors={form.errors} id={form.errorId} />

            <div className="flex items-center justify-between gap-6 pt-3">
              <StatusButton
                className="w-full font-bold"
                status={isPending ? 'pending' : form.status ?? 'idle'}
                type="submit"
                disabled={isPending}
              >
                Iniciar sesión
              </StatusButton>
            </div>
          </Form>
        </div>
      </div>
    </AuthLayout>
  )
}

export function ErrorBoundary() {
  return <GeneralErrorBoundary />
}

